import React, { useState } from 'react';
import axios from 'axios';
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from 'react-i18next';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Link from '@material-ui/core/Link';
import { flash_alert } from 'components/App';

const DELETE_TECHNICIAN = "DELETE_TECHNICIAN";
const DELETED_TECHNICIAN = "DELETED_TECHNICIAN";

function DeleteTechnicianDialog({ technician_id, name, lastname, headers }) {
	const [open, setOpen] = useState(false);
	const dispatch = useDispatch();
	const { t } = useTranslation();

	const toggleDialog = (state) => () => setOpen(state);

	const deleteTechnician = (technician_id, headers) => {
		return async (dispatch) => {
			dispatch({ type: DELETE_TECHNICIAN });
			try {
				const response = await axios.delete(`/api/v1/technicians/${technician_id}`, { headers });
				dispatch({ type: DELETED_TECHNICIAN, response });
				flash_alert(t('globalText.removed'), t('technicians.flashAlert.success'), "success")
				window.location.reload();
			} catch (error) {
				flash_alert(t('globalText.error'), t('technicians.flashAlert.failed'), "danger")
			}
		};
	}

	const handleDelete = async (e) => {
		e.preventDefault();
		setOpen(false);
		dispatch(deleteTechnician(technician_id, headers));
};

	return (
			<>
					<Link
							href="#"
							id={`technician_${technician_id}`}
							className="mdl-navigation__link delete-technician-link"
							onClick={toggleDialog(true)}
					>
							<i className="material-icons material-icons-20">delete</i> {t('technicians.confirm.erase')}
					</Link>
					<Dialog open={open} onClose={toggleDialog(false)}>
							<DialogTitle>{t('technicians.confirm.delete?')}</DialogTitle>
							<DialogContent>
									<DialogContentText>
											{t('technicians.confirm.alertDialog')} {name} {lastname}
									</DialogContentText>
							</DialogContent>
							<DialogActions>
									<Button onClick={toggleDialog(false)} color="primary">
											{t('technicians.confirm.no')}
									</Button>
									<Button onClick={handleDelete} color="primary" autoFocus>
											{t('technicians.confirm.yes')}
									</Button>
							</DialogActions>
					</Dialog>
			</>
	);
}

export default DeleteTechnicianDialog;
